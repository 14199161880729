<template>
  <div class="bg-white pb-3">
    <div class="mt-3">
      <b-table
        ref="tablecustom"
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:cell(first_name_th)="data">
          <b-row>
            <b-col>
              <router-link :to="`/report/customer/${data.item.user_guid}`">
                <u>
                  {{ data.item.first_name_th }} {{ data.item.last_name_th }}</u
                >
              </router-link>
            </b-col>
          </b-row>
        </template>
        <template v-slot:cell(member_level_name)="data">
          <b-row>
            <b-col class="text-right p-0" cols="3"
              ><img :src="data.item.member_level_icon" class="icon-level"
            /></b-col>
            <b-col class="text-left" cols="9">{{
              data.item.member_level_name
            }}</b-col>
          </b-row>
        </template>
        <template v-slot:cell(email)="data">
          <div class="text-left">
            {{ data.item.email ? data.item.email : "-" }}
          </div>
        </template>
        <template v-slot:cell(point_total)="data">
          <div>{{ data.item.point_total | numeral("0,0") }}</div>
        </template>
        <template v-slot:cell(grand_total)="data">
          <div>{{ data.item.grand_total | numeral("0,0.00") }}</div>
        </template>
        <template v-slot:cell(created_time)="data">
          <div>{{ data.item.created_time | moment($formatDateNew) }}</div>
          <div class="time">
            {{ data.item.created_time | moment("HH:mm:ss") }}
          </div>
        </template>
        <template v-slot:cell(is_consent)="data">
          <div
            :class="
              data.item.is_consent == 0
                ? 'status-cancel'
                : data.item.is_consent == 1
                ? 'consent-allow'
                : ''
            "
          >
            {{
              data.item.is_consent == 0
                ? "ไม่ยินยอม"
                : data.item.is_consent == 1
                ? "ยินยอม"
                : data.item.is_consent == 2
                ? "รอการยินยอม"
                : data.item.is_consent == 3
                ? "Fail Consent 1"
                : data.item.is_consent == 4
                ? "Fail Consent 2"
                : data.item.is_consent == 5
                ? "Fail Consent 3"
                : data.item.is_consent == 6
                ? "ถอดถอน"
                : "ระงับ"
            }}
          </div>
        </template>
        <template v-slot:cell(action)="data">
          {{ data.item.action }}
          <b-button
            class="btn-see-all"
            @click.prevent="
              $router.push(`/report/customer/${data.item.user_guid}`)
            "
          >
            <div class="text-underline">Detail</div>
          </b-button>
        </template>
        <!-- Report Transaction -->
        <template v-slot:cell(invoice_no)="data">
          <div class="text-left title-transaction pointer">
            <router-link
              :to="`/report/transaction/${data.item.transaction_id}`"
              >{{
                data.item.invoice_no ? data.item.invoice_no : "-"
              }}</router-link
            >
          </div>
        </template>
        <template v-slot:cell(member_id)="data">
          <div class="text-left">
            <div
              class="title-transaction pointer"
              v-if="data.item.member_id"
              @click="$router.push(`/report/customer/${data.item.user_guid}`)"
            >
              {{ data.item.member_id }}
            </div>
            <div class="title-transaction" v-else>-</div>
            <div>
              {{ data.item.customer_name ? data.item.customer_name : "-" }}
            </div>
          </div>
        </template>
        <template v-slot:cell(status_name)="data">
          <div>
            <div v-if="data.item.status_id === 1" class="consent-allow">
              <span>สำเร็จ </span>
              <span class="status-void" v-if="data.item.is_void === 1"
                >(ขอคืน)</span
              >
            </div>
            <div
              v-if="data.item.status_id === 2 || data.item.status_id === 3"
              class="status-void"
            >
              ขอคืน
            </div>
            <div v-if="data.item.status_id === 4" class="status-cancel">
              ยกเลิก
            </div>
          </div>
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
    </div>
    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
  components: { Pagination },
  props: {
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    showing: {
      required: true,
      type: Number,
    },
    showingTo: {
      required: true,
      type: Number,
    },
    rows: {
      required: true,
      type: Number,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    filter: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      selectId: [],
      toShow: this.showingTo,
      showings: this.showing,
    };
  },
  methods: {
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showings = this.filter.page;
        this.toShow = this.filter.take;
      } else {
        this.showings = value * this.filter.take - this.filter.take;
        this.toShow = this.showings + this.filter.take;
      }
      this.$emit("page", this.filter);
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.toShow = this.filter.take;
      this.$emit("handleChangeTake", this.filter);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}
.text-underline {
  text-decoration: underline;
}
.icon-level {
  height: auto;
  width: auto;
  max-width: 23px;
  max-height: 23px;
}
.color-name {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  padding: 5px 0;
  font-size: 20px;
  font-weight: 600;
}
.color-odd {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}
.color-even {
  background-color: #acd3ec;
  color: #1d9cee;
}
.btn-see-all {
  background-color: transparent;
  border: none;
}
.consent-allow {
  color: #06c306;
}
.status-return-1 {
  color: #cd3000;
}
.status-cancel {
  color: red;
}
.status-void {
  color: var(--primary-color);
}
.status-return {
  color: #ddb500;
}
.title-transaction {
  text-decoration: underline;
  color: #000;
}
.time {
  color: #b5b5b5;
}
</style>
